import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Label, Input } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "label"
    }}>{`Label`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Label htmlFor=\"labelInputId\">Label text</Label>\n<Input id=\"labelInputId\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Label,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Label htmlFor="labelInputId" mdxType="Label">Label text</Label>
  <Input id="labelInputId" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Label} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      